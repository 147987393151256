import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Carousel from "nuka-carousel"

const SurreyHillsPage = ({ data }) => (
  <Layout>
    <SEO title="Our Surrey Hills Project" />
    <h1>Our Surrey Hills Project</h1>

    <div className="flex justify-center">
      <div className="w-2/3 h-auto py-6">
        <Carousel renderBottomCenterControls={false}>
          {data.surreyHillsPhotos.edges.map(edge => {
            return <Img fluid={edge.node.childImageSharp.fluid} />
          })}
        </Carousel>
      </div>
    </div>
  </Layout>
)

export default SurreyHillsPage

export const query = graphql`
  query {
    surreyHillsPhotos: allFile(
      filter: { 
        absolutePath: { regex: "/projects/residential/surrey-hills/" }
        extension: { regex: "/(jpg)|(jpeg)/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
